<template>
	<main-tabs :links="links">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/ad-switch/history'
					},
					label: '开关记录'
				}, {
					to: {
						path: '/ad-switch/config'
					},
					label: '规则配置'
				}]
			}
		}
	}
</script>
